import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"

import NextierColored from "../images/nextier-colored.svg"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterData {
      allStrapiGlobal {
        nodes {
          contactAddress
          contactEmail
          contactPhone
          footercta {
            Text
            Title
            id
            Button {
              Link
              Text
            }
            Image {
              alternativeText
              url
            }
          }
        }
      }
    }
  `)

  console.log("Footer data", data)

  // data.allStrapiGlobal.nodes[0]
  const title = data.allStrapiGlobal.nodes[0].footercta.Title
  const text = data.allStrapiGlobal.nodes[0].footercta.Text
  const buttonText = data.allStrapiGlobal.nodes[0].footercta.Button.Text
  const buttonLink = data.allStrapiGlobal.nodes[0].footercta.Button.Link
  const image = data.allStrapiGlobal.nodes[0].footercta.Image.url

  return (
    <footer className="nextier-footer">
      <div className="container nextier-footer__cta">
        <div
          className="nextier-footer__cta-background"
          style={{ backgroundImage: `url(${image})` }}
        >
          <div className="nextier-footer__cta-content">
            <h3>{title}</h3>
            <p>{text}</p>
            <Link to={buttonLink} className="primary-btn_alt btn-arrow">
              <span>{buttonText}</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="nextier-footer__content">
        <div className="container">
          <div className="nextier-footer__logo">
            <img src={NextierColored} />
          </div>
          <div className="nextier-footer__contact-links">
            <div className="contact-links">
              <a href={`tel:${data.allStrapiGlobal.nodes[0].contactPhone}`}>
                {data.allStrapiGlobal.nodes[0].contactPhone}
              </a>
              <a herf={`mailto:${data.allStrapiGlobal.nodes[0].contactEmail}`}>
                {data.allStrapiGlobal.nodes[0].contactEmail}
              </a>
            </div>
            <div className="contact-links">
              <span
                className=""
                dangerouslySetInnerHTML={{
                  __html: data.allStrapiGlobal.nodes[0].contactAddress,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="nextier-footer__navigation">
        <div className="nextier-footer__navgrid">
          <div>
            <h5 className="nextier-footer__navheader">Products + Services</h5>
            <ul>
              <li className="nextier-footer__navlink">
                <Link to="/products">Plastics</Link>
              </li>
              <li className="nextier-footer__navlink">
                <Link to="/services">Technical Services</Link>
              </li>
              <li className="nextier-footer__navlink">
                <Link to="/industries">Industries</Link>
              </li>
            </ul>
          </div>
          <div>
            <h5 className="nextier-footer__navheader">About + Contact</h5>
            <ul>
              <li className="nextier-footer__navlink">
                <Link to="/about">About</Link>
              </li>
              <li className="nextier-footer__navlink">
                <Link to="/partners">Our Partners</Link>
              </li>
              <li className="nextier-footer__navlink">
                <Link to="/contact">Contact Us</Link>
              </li>
              {/* <li className="nextier-footer__navlink">
                <Link to="/">Credit Application</Link>
              </li>
              <li className="nextier-footer__navlink">
                <Link to="/">Customer Portal</Link>
              </li> */}
            </ul>
          </div>
          {/* <div>
            <h5 className="nextier-footer__navheader">Latest news</h5>
            <ul>
              <li className="nextier-footer__navlink">
                <a href="/">Blog Article Title One</a>
              </li>
              <li className="nextier-footer__navlink">
                <a href="/">Blog Article Title Two</a>
              </li>
              <li className="nextier-footer__navlink">
                <a href="/">Blog Article Title Three</a>
              </li>
            </ul>
          </div> */}
        </div>
      </div>
      <div className="nextier-footer__subfooter">
        <div className="container">
          <p>©{new Date().getFullYear()} Nextier Plastics</p>
          <div>
            <a href="https://nextiersourcing.com/privacy-policy">
              Privacy Policy
            </a>
            {/* <span>&#8214;</span>
            <a href="/">Terms &amp; Condidtions</a> */}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
